import { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { fetchMenusData, initApplication, platformExports, redirectIfURLIsInvalid } from './viewer-app-module';
import { initializeMonitoring, toMonitored } from './viewer-app-module/utils/monitoring';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowApi,
) => {
  initializeMonitoring(initParams, platformServices);
  const { httpClient } = flowApi;
  await toMonitored('initAppForPage', () =>
    initApplication(initParams as any, platformApis as any, wixCodeApi, httpClient, flowApi.experiments),
  )();
  await redirectIfURLIsInvalid(wixCodeApi, platformApis);

  return fetchMenusData({ appParams: initParams, wixCodeApi, flowApi });
};

export const exports = () => platformExports;
